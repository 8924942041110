import React, { useState, useEffect, useRef } from "react"
import Review1 from "../assets/img/review-1.svg"
import Review2 from "../assets/img/review-2.svg"
import Review3 from "../assets/img/review-3.svg"
import Review4 from "../assets/img/review-4.svg"
import Review5 from "../assets/img/review-5.svg"
import "../styles/carousel.scss"

export default function Carousel() {
  const [isPaused, setIsPaused] = useState(false)
  const [initialX, setInitialX] = useState(0)
  const [currentX, setCurrentX] = useState(0)
  const carouselRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const carouselContainer = carouselRef.current

    const handleClick = () => {
      setIsPaused(!isPaused)
    }

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
      setIsPaused(!isPaused)
      setInitialX(event.clientX)
    }

    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
      if (!isPaused && initialX !== 0) {
        const distance = event.clientX - initialX
        setCurrentX(distance * 2) // Multiply by 4 to increase the movement
      }
    }

    const handleMouseUp = () => {
      setInitialX(0)
    }

    const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
      setIsPaused(!isPaused)
      setInitialX(event.touches[0].clientX)
    }

    const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
      if (!isPaused && initialX !== 0) {
        const distance = event.touches[0].clientX - initialX
        setCurrentX(distance * 2) // Multiply by 4 to increase the movement
      }
    }

    const handleTouchEnd = () => {
      setInitialX(0)
    }

    if (carouselContainer) {
      carouselContainer.addEventListener("click", handleClick)
      carouselContainer.addEventListener("mousedown", handleMouseDown)
      carouselContainer.addEventListener("mousemove", handleMouseMove)
      carouselContainer.addEventListener("mouseup", handleMouseUp)
      carouselContainer.addEventListener("touchstart", handleTouchStart)
      carouselContainer.addEventListener("touchmove", handleTouchMove)
      carouselContainer.addEventListener("touchend", handleTouchEnd)
    }

    return () => {
      if (carouselContainer) {
        carouselContainer.removeEventListener("click", handleClick)
        carouselContainer.removeEventListener("mousedown", handleMouseDown)
        carouselContainer.removeEventListener("mousemove", handleMouseMove)
        carouselContainer.removeEventListener("mouseup", handleMouseUp)
        carouselContainer.removeEventListener("touchstart", handleTouchStart)
        carouselContainer.removeEventListener("touchmove", handleTouchMove)
        carouselContainer.removeEventListener("touchend", handleTouchEnd)
      }
    }
  }, [isPaused, initialX])

  return (
    <div
      className="Carousel"
      style={{
        transform: `translateX(${currentX}px)`,
        transition: isPaused ? "none" : "transform 0.3s ease",
      }}
      ref={carouselRef}
    >
      <div className="carouselItems a">
        <img src={Review1} alt="Review de um usuário" />
        <img src={Review2} alt="Review de um usuário" />
        <img src={Review3} alt="Review de um usuário" />
        <img src={Review4} alt="Review de um usuário" />
        <img src={Review5} alt="Review de um usuário" />
      </div>

      <div className="carouselItems b">
        <img src={Review1} alt="Review de um usuário" />
        <img src={Review2} alt="Review de um usuário" />
        <img src={Review3} alt="Review de um usuário" />
        <img src={Review4} alt="Review de um usuário" />
        <img src={Review5} alt="Review de um usuário" />
      </div>
    </div>
  )
}
