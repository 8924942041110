import * as React from "react"
import { useState, useEffect } from "react"
import SuccessToast from "../assets/success-toast.svg"
import { StaticImage } from "gatsby-plugin-image"
import Close from "../assets/close.svg"
import SuccessCloseToast from "../assets/successCloseToast.svg"
import ErrorStatusToast from "../assets/errorStatusToast.svg"
import "../styles/toast.scss"

const styles = {
  successContainer: {
    backgroundColor: "#F2FFF6",
  },

  errorContainer: {
    backgroundColor: "#FFF3F4",
  },

  successContainerHideAnimation: {
    backgroundColor: "#f2fff6)",
    animation: "hideToast 0.5s ease-in-out forwards",
  },

  errorContainerHideAnimation: {
    backgroundColor: "#FFF3F4",
    animation: "hideToast 0.5s ease-in-out forwards",
  },

  successBar: {
    backgroundColor: "#65D482",
  },

  errorBar: {
    backgroundColor: "#FC2D3F",
  },

  showToast: {
    display: "flex",
    position: "fixed",
    top: "24px",
    transform: "translateY(0)",
    transition: "transform 0.3s ease-in-out",
  },

  showToastMobile: {
    display: "flex",
    position: "fixed",
    top: "76%",
    transform: "translateY(0)",
    transition: "transform 0.3s ease-in-out",
  },

  hideToast: {
    display: "none",
  },
}

type ToastProps = {
  status: string
  message: string
  show: boolean
  setShow: (show: boolean) => void
}

const ToastStatus: React.FC<ToastProps> = ({
  show,
  setShow,
  status,
  message,
}) => {
  const [hide, setHide] = useState(false)

  useEffect(() => {
    let timer: NodeJS.Timeout
    let hideTimer: NodeJS.Timeout
    if (show) {
      timer = setTimeout(() => {
        setHide(true)
        hideTimer = setTimeout(() => {
          setShow(false)
          setHide(false)
        }, 500) // 0.5 seconds for the hide animation
      }, 10000) // 10 seconds for showing the toast
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
      if (hideTimer) {
        clearTimeout(hideTimer)
      }
    }
  }, [show, setShow])

  return (
    <div
      className="toastStatusContainer"
      style={
        show
          ? window.innerWidth < 769
            ? styles.showToastMobile
            : styles.showToast
          : hide
          ? {}
          : styles.hideToast
      }
    >
      <div
        className="toastStatus"
        style={
          status == "success"
            ? hide
              ? styles.successContainerHideAnimation
              : styles.successContainer
            : hide
            ? styles.errorContainerHideAnimation
            : styles.errorContainer
        }
      >
        <div
          className="toastBar"
          style={status == "success" ? styles.successBar : styles.errorBar}
        ></div>

        <div className="toastIcon">
          {status === "success" ? (
            <img src={SuccessToast} alt="Ícone de sucesso" />
          ) : (
            <img src={ErrorStatusToast} alt="Ícone de erro" />
          )}
        </div>
        <div className="toastText">
          {status === "success" ? (
            <p className="toastStatusText">Sucesso</p>
          ) : (
            <p className="toastStatusText">Erro</p>
          )}

          <p className="statusDescription">{message}</p>
        </div>

        <div className="closeToastContainer">
          {status === "success" ? (
            <button
              className="closeToast"
              onClick={() => {
                setShow(!show)
              }}
            >
              <img
                src={SuccessCloseToast}
                alt=""
                className="closeToastStatus"
                width="40px"
                height="40px"
              />
            </button>
          ) : (
            <button className="closeToast">
              <img
                src={Close}
                alt=""
                className="closeToastStatus"
                onClick={() => {
                  setShow(!show)
                }}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export { ToastStatus }
