import React, { FC, useState, useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { analyticsTrack } from "../utils"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import {
  Layout,
  LoadingScreen,
  Participant,
  ParticipantForm,
  Popup,
} from "../components"
import IphonesSvg from "../assets/iphones.svg"

import Questions from "../assets/img/questions.svg"
import Dog from "../assets/img/dog.svg"
import Carousel from "../components/Carousel"

import { ToastStatus } from "../components/ToastStatus"
import { StaticImage } from "gatsby-plugin-image"

import { useAppSelector } from "../app/hooks"

interface State {
  loading: boolean
  participants?: any
  group?: {
    name: string
    comments: string
  }
}

const ErrorPage = ({ error }: any) => {
  const language = useAppSelector((state) => state.language.config)
  const texts = language.Errors

  return (
    <>
      <section className="errorContainer">
        {error != "500" ? (
          <>
            <div className="errorTextContainer">
              <p className="errorTitle">{texts.oops}</p>
              {error == "groupNotFound" ? (
                <p className="errorDescription">
                  {texts.groupDeleted}
                  <br /> {texts.groupDeletedBody}
                  <br />
                  <br />
                  {texts.groupDeletedSuggestion}
                </p>
              ) : (
                <p className="errorDescription">
                  {texts.emailDeleted}
                  <br /> {texts.emailDeletedSuggestion}
                </p>
              )}

              <p className="helpWarning">
                {texts.needHelp} <a className="helpHighlight">{texts.faq}</a>
              </p>
            </div>

            <img
              src={Questions}
              alt="Imagem indicando que houve erro na página. "
              className="errorQuestionsImage"
            />
          </>
        ) : (
          <>
            <div className="internalServerErrorContainer">
              <img
                src={Dog}
                alt="Imagem indicando que houve erro na página."
                className="dogErrorImage"
              />

              <div className="internalServerErrorTextContainer">
                <p className="internalServerErrorTitle">{texts["500"].title}</p>

                <p className="internalServerErrorDescription">
                  {texts["500"].body}
                  <br />
                  <br />
                  {texts["500"].warning}
                </p>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  )
}

const Content = ({ participants, group, showFriendOnClick }) => {
  const language = useAppSelector((state) => state.language.config)
  const texts = language.Draw

  const [secretFriend, setSecretFriend] = useState([])

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState("")
  const [toastStatus, setToastStatus] = useState("")

  const [
    shuffledparticipantsWithoutSecretFriend,
    setShuffledparticipantsWithoutSecretFriend,
  ] = useState([])

  useEffect(() => {
    const shuffleArray = (array: never[]) => {
      const arrayCopy = [...array]
      for (let i = arrayCopy.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        const temp = arrayCopy[i]
        arrayCopy[i] = arrayCopy[j]
        arrayCopy[j] = temp
      }
      return arrayCopy
    }

    setShuffledparticipantsWithoutSecretFriend(
      shuffleArray(
        participants.others.filter(
          (participant: { name: string }) =>
            participant.name.trim() !== participants.current.friend.trim()
        )
      )
    )

    setSecretFriend(
      participants.others.find(
        (participant: { name: any }) =>
          participant.name.trim() === participants.current.friend.trim()
      )
    )
  }, [])

  const goToAdvertisePage = (link, category, action, label) => {
    analyticsTrack(category, action, label)
    window.open(link, "_blank", 'rel="noreferrer"')
  }

  const [translateValue, setTranslateValue] = useState(0)
  const [goRight, setGoRight] = useState(1)
  const carouselRef = useRef(null)
  const dragStartRef = useRef(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setTranslateValue(translateValue + 220 * goRight)

      if (translateValue >= 660) {
        setGoRight(-1)
      } else if (translateValue <= 0) {
        setGoRight(1)
      }
    }, 2000)

    return () => clearInterval(interval)
  }, [translateValue])

  const handleMouseDown = (e) => {
    dragStartRef.current = e.clientX
    document.addEventListener("mousemove", handleMouseMove)
    document.addEventListener("mouseup", handleMouseUp)
  }

  const handleMouseMove = (e) => {
    const dragDistance = (e.clientX - dragStartRef.current) * 20
    setTranslateValue(translateValue + dragDistance)
    dragStartRef.current = e.clientX
  }

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove)
    document.removeEventListener("mouseup", handleMouseUp)
  }

  // User-initiated dragging on touch start
  const handleTouchStart = (e) => {
    dragStartRef.current = e.touches[0].clientX
  }

  const handleTouchMove = (e) => {
    const dragDistance = e.touches[0].clientX - dragStartRef.current
    setTranslateValue(translateValue + dragDistance)
    dragStartRef.current = e.touches[0].clientX
  }

  return (
    <>
      <ToastStatus
        show={showToast}
        setShow={setShowToast}
        message={toastMessage}
        status={toastStatus}
      />
      <section className="banner">
        <div className="hero">
          <div className="valueProposition">{texts.slogan}</div>

          <img
            src={IphonesSvg}
            alt="Imagem de Iphones"
            className="iphones desktop"
          />

          {window.innerWidth < 769 && (
            <StaticImage
              src="https://raw.githubusercontent.com/papelzinho/assets/main/iphones.png"
              alt="Imagem de Iphones"
              className="iphones globalMobile"
            />
          )}
        </div>
      </section>

      <div className="page-container">
        <div className="pageContent">
          <section className="mb-4">
            <div className="groupInfoContainer">
              <p className="welcomeText">
                {texts.greeting} {participants.current.name},
              </p>

              <div className="groupNameAndMembersNumber">
                <div className="groupTextName">
                  {texts.youAre} <span className="groupName">{group.name}</span>
                </div>

                <span className="group__participants">
                  {participants.others.length + 1} {texts.participants}
                </span>
              </div>

              <p className="group__description">{group.comments}</p>

              <div className="showFriendButtonContainer">
                <button className="show-friend-btn" onClick={showFriendOnClick}>
                  {texts.revealSecretFriend}
                </button>
              </div>
            </div>
          </section>

          <div className="groupFriendAndYouRow" id="giftList">
            <Participant participant={secretFriend} isSecretFriend={true} />
            <ParticipantForm
              participant={participants.current}
              showToast={showToast}
              setShowToast={setShowToast}
              toastMessage={toastMessage}
              setToastMessage={setToastMessage}
              toastStatus={toastStatus}
              setToastStatus={setToastStatus}
            />
          </div>

          <OutboundLink
            href="https://meliuz.onelink.me/2657008059/lfxerda1"
            target="_blank"
            onClick={() => {
              analyticsTrack("home-acesso-méliuz", "clique", "banner-méliuz")
            }}
          >
            <div className="adSeparator">
              <StaticImage
                src="https://raw.githubusercontent.com/papelzinho/assets/main/banner-meluiz-pascoa-site.png"
                alt="Banner da Méliuz"
              />
            </div>

            <div className="adSeparatorMobileContainer">
              <StaticImage
                src="https://raw.githubusercontent.com/papelzinho/assets/main/banner-meliuz-pascoa-site-mobile.png"
                alt="Banner da Méliuz"
                className="adSeparatorMobile"
              />
            </div>
          </OutboundLink>

          <div className="separator"></div>

          <p className="othersParticipantsChoicesTitle">
            {texts.othersParticipantsChoices}
          </p>

          <div className="row otherParticipants">
            {shuffledparticipantsWithoutSecretFriend.map(
              (participant, index) => (
                <Participant key={index} participant={participant} />
              )
            )}
          </div>
        </div>

        <div className="reviewsContainer">
          <div className="reviewsTitle">{texts.ratings}</div>
          <Carousel />
        </div>
      </div>
    </>
  )
}

const SorteioPage: FC = () => {
  const initialState: State = {
    loading: true,
  }
  const [state, setState] = useState(initialState)
  const [showPopup, setShowPopup] = useState(false)
  const [showSecretFriend, setShowSecretFriend] = useState(false)
  const [error, setError] = useState("")
  const [participantId, setParticipantId] = useState("")

  const showFriend: () => void = () => {
    updateParticipant(participantId)
    setShowSecretFriend(true)
    setShowPopup(true)
  }

  const updateParticipant = async (participantId: any) => {
    try {
      const response = await fetch(
        `https://u39ua4g3tg.execute-api.us-east-2.amazonaws.com/Prod/participant/${participantId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            raffleStatus: "read",
          }),
        }
      )

      if (!response.ok) {
        throw new Error("Failed to update participant")
      }

      const data = await response.json()
      console.log("Updated participant:", data)
    } catch (error) {
      console.error("Error updating participant:", error)
    }
  }

  const hideFriend = () => {
    analyticsTrack("cancel-button", "clique", "Campanha Méliuz")

    setTimeout(() => {
      goToGiftList()
    }, 10)
  }

  const giftListOnClick = () => {
    analyticsTrack("home-modal-revelação", "clique", "botão-lista-presentes")

    setTimeout(() => {
      goToGiftList()
    }, 80)
  }

  const goToGiftList = () => {
    setShowPopup(false)
    const giftList = document.getElementById("giftList")

    if (giftList) {
      giftList.scrollIntoView({ behavior: "smooth" })
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const groupId = query.get("g")
    const personId = query.get("p")
    setParticipantId(personId || "")

    const fetchData = async () => {
      const shouldFetchFromNewApi = await setRaffleData(groupId, personId)

      if (shouldFetchFromNewApi) {
        fetchDataFromOldApi(groupId, personId)
      }
    }

    fetchData()
  }, [false])

  const fetchDataFromOldApi = async (groupId: any, personId: any) => {
    fetch(
      `https://lm4ipcuu6k.execute-api.us-west-2.amazonaws.com/prd/participant/${groupId}/${personId}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Group not found") {
          setError("groupNotFound")
          setState({ loading: false })
          return
        } else if (data.message === "Participant not found") {
          setError("participantNotFound")
          setState({ loading: false })
          return
        }

        const participants = data.participants.reduce(
          (acc: any, participant: { current: any }) => {
            const newMap = acc
            if (participant.current) {
              newMap["current"] = {
                ...participant,
                groupId,
              }
            } else if (newMap["others"]) {
              newMap["others"].push(participant)
            } else {
              newMap["others"] = [participant]
            }
            return newMap
          },
          {}
        )

        const participant = participants.current
        const participantObjectFormatted = {}
        participantObjectFormatted["id"] = participant.id
        participantObjectFormatted["name"] = participant.name
        participantObjectFormatted["email"] = participant.email
        participantObjectFormatted["friend"] = participant.friend

        if (
          participant.option1 !== undefined ||
          participant.option2 !== undefined ||
          participant.option3 !== undefined
        ) {
          participantObjectFormatted["options"] = [
            participant.option1,
            participant.option2,
            participant.option3,
          ]
        }

        if (participant.comment !== undefined) {
          participantObjectFormatted["comments"] = participant.comment
        }

        participants.current = participantObjectFormatted

        for (let i = 0; i < participants.others.length; i++) {
          const participant = participants.others[i]
          const participantObjectFormatted = {}
          participantObjectFormatted["id"] = participant.id
          participantObjectFormatted["name"] = participant.name
          participantObjectFormatted["email"] = participant.email

          if (
            participant.option1 !== undefined ||
            participant.option2 !== undefined ||
            participant.option3 !== undefined
          ) {
            participantObjectFormatted["options"] = [
              participant.option1,
              participant.option2,
              participant.option3,
            ]
          }

          if (participant.comment !== undefined) {
            participantObjectFormatted["comments"] = participant.comment
          }

          participants.others[i] = participantObjectFormatted
        }

        setState({
          loading: false,
          participants,
          group: { name: data.name, comments: data.comments },
        })
        setShowPopup(true)
      })
      .catch(() => {
        alert(
          "Sistema fora do ar. Estamos trabalhando para corrigir o problema o mais rápido possível. Tente acessar novamente mais tarde."
        )
      })
  }

  const setRaffleData = async (groupId: any, personId: any) => {
    try {
      const response = await fetch(
        `https://80c14gw0pj.execute-api.us-east-2.amazonaws.com/Prod/${groupId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

      const group = await response.json()

      if (group.data === undefined) {
        throw new Error("groupNotFound")
      }

      const responseParticipants = await fetch(
        `https://80c14gw0pj.execute-api.us-east-2.amazonaws.com/Prod/get-participants`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ids: group.data.participants }),
        }
      )

      const participantsJson = await responseParticipants.json()
      checkIfParticipantExists(personId, participantsJson.data.ParticipantProd)

      const participants = participantsJson.data.ParticipantProd.reduce(
        (acc: any, participant: { current: any }) => {
          const newMap = acc
          if (participant.id == personId) {
            const friendId = group.data.raffle[participant.id]
            const friend = participantsJson.data.ParticipantProd.find(
              (p) => p.id == friendId
            )
            participant.friend = friend.name
            newMap["current"] = {
              ...participant,
              groupId,
            }
          } else if (newMap["others"]) {
            newMap["others"].push(participant)
          } else {
            newMap["others"] = [participant]
          }
          return newMap
        },
        {}
      )

      setState({
        loading: false,
        participants,
        group: {
          name: group.data.name,
          comments: group.data.description || "",
        },
      })

      setShowPopup(true)
      return false
    } catch (error: any) {
      if (error.message == "participantNotFound") {
        setError("participantNotFound")
        setState({ loading: false })
        return false
      }

      return true
    }
  }

  const checkIfParticipantExists = (personId: string, participants: any) => {
    if (participants.length === 0) {
      throw new Error("participantNotFound")
    }

    for (let i = 0; i < participants.length; i++) {
      if (participants[i].id == personId) {
        break
      }

      if (i == participants.length - 1) {
        throw new Error("participantNotFound")
      }
    }
  }

  const languages = useAppSelector((state) => state.language.config)
  const texts = languages.Header
  return (
    <Layout>
      <Helmet>
        <title>{texts.title} ™</title>
      </Helmet>

      {state.loading ? (
        <LoadingScreen />
      ) : error != "" ? (
        <ErrorPage error={error} />
      ) : (
        <Content
          showFriendOnClick={showFriend}
          group={state.group}
          participants={state.participants}
        />
      )}

      {showPopup && (
        <Popup
          personId={participantId}
          message={`"${state.participants.current.friend}"`}
          action2={hideFriend}
          showSecretFriend={showSecretFriend}
          goToGiftList={giftListOnClick}
        />
      )}
    </Layout>
  )
}

export default SorteioPage
